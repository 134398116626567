<template>
  <div>
    <l-button
      v-auth="'AUTH_ROLE-SINGLE_SAVE'"
      type="primary"
      class="c-top_right_btn"
      @click="editRow()"
    >
      新建角色
    </l-button>

    <l-search @search="search(1)" @reset="resetForm">
      <l-form layout="inline" laba-width="70px">
        <l-form-item label="集团">
          <l-select v-model="searchFilter.companyId" placeholder="请选择集团">
            <l-select-option label="全部" :value="-2">全部</l-select-option>
            <l-select-option label="运营支撑系统" :value="-1">运营支撑系统</l-select-option>
            <l-select-option v-for="item in companyList" :key="item.id" :value="item.id">{{ item.name }}</l-select-option>
          </l-select>
        </l-form-item>
      </l-form>
    </l-search>

    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column data-index="id" title="流水号" />
      <l-table-column data-index="name" title="角色名字" />
      <l-table-column data-index="code" title="角色编码" />
      <l-table-column data-index="appName" title="系统名字" />
      <l-table-column title="创建时间">
        <template slot-scope="scope">
          {{ scope.createTime | date }}
        </template>
      </l-table-column>
      <l-table-column data-index="createName" title="创建人" />
      <l-table-column title="更多">
        <template v-if="scope.isEdit !== 0" slot-scope="scope">
          <l-button
            v-auth="'AUTH_ROLE-SINGLE_SAVE'"
            type="link"
            @click="editRow(scope)"
          >
            编辑
          </l-button>
          <l-button
            v-auth="'AUTH_ROLE-SAVE'"
            type="link"
            @click="$toPage('/auth-role/edit',{id:scope.id})"
          >
            角色权限
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id?'修改角色':'添加角色'"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="角色名字" prop="name" required>
          <l-input
            v-model="editDialog.data.name"
            placeholder="请输入角色名字"
          />
        </l-form-model-item>
        <l-form-model-item label="角色编码" prop="code" required>
          <l-input
            v-model="editDialog.data.code"
            placeholder="请输入角色编码"
            :disabled="!!editDialog.data.id"
          />
        </l-form-model-item>
        <l-form-model-item label="关联系统" prop="appId" required>
          <l-select
            v-model="editDialog.data.appId"
            placeholder="请选择系统"
            :disabled="!!editDialog.data.id"
          >
            <l-select-option
              v-for="item in appList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.cnName }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthRoleList',
  data() {
    return {
      searchFilter: {
        companyId: -1
      },
      appList: [],
      loading: false,
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
      editDialog: {
        data: {
          id: '',
          appId: '',
          code: '',
          name: ''
        },
        save: false,
        show: false
      }
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.auth.companyList
    })
  },
  mounted() {
    this.search(1)
    this.$store.dispatch('auth/authCompany')
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page
      const param = {
        page: page || 1,
        size: this.tableData.size,
        companyId: this.searchFilter.companyId
      }
      this.tableData.list = []
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('auth/authRole', param)
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page || 1
      } catch (e) {
        this.tableData.isLoading = false
      }
    },
    resetForm() {
      this.searchFilter = this.$options.data().searchFilter
      this.search(1)
    },
    editRow(row) {
      row = row || {}
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      for (const key in this.editDialog.errmsg) {
        this.editDialog.errmsg[key] = ''
      }
      this.remoteMethod()
      this.editDialog.show = true
    },
    async saveDialog() {
      this.editDialog.save = true
      try {
        await this.$store.dispatch('auth/authRoleSingleSave', this.editDialog.data)
        this.editDialog.show = false
        this.editDialog.save = false
        this.search()
      } catch (e) {
        this.editDialog.save = false
      }
    },
    async remoteMethod(query) {
      this.loading = true
      const data = await this.$store.dispatch('auth/authAppList', {
        quickSearch: query
      })
      this.appList = data.list
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.errmsg,
.require {
  color: $l-danger;
}
.system-index-btns {
  text-align: right;
  & i {
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: top;
  }
}
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .l-dialog__header {
    border-bottom: 1px solid $l-border-base;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
